import styled from "styled-components"; 
import { Link } from "react-router-dom";

export const InfoContainer = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${({ greyGround }) => ( greyGround ? 'var(--main-grey)' : 'var(--main-white)')};

`
export const InfoWrapper = styled.div`
   display: grid; 
   z-index: 1; 
   width: inherit; 
   height: 100vh;
   margin-left: auto; 
   margin-right: auto; 
   padding: 0 24px; 
`

export const InfoRow = styled.div`
   display: ${({ toggleNav}) => ( toggleNav ? 'none' : 'grid')}; 
   grid-auto-columns: minmax(auto, 1.5fr); 
   grid-template-areas: ${({imageStart}) => ( imageStart ? `'col2 col1'` : `'col1 col2'`)};  
   align-items: center;

   @media screen and (max-width: 40em){
       grid-template-areas: ${({ imageStart }) => (imageStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
   }
`

export const Column1 = styled.div`
    margin-top: 50px;
    margin-bottom: 15px; 
    padding: 0 15px; 
    grid-area: col1;
` 

export const Column2 = styled.div`
    margin-bottom: 15px; 
    padding: 0 15px; 
    grid-area: col2; 
` 
export const ImgWrapper = styled.div`
    max-width: 100rem; 
    max-height: 10rem; 
` 
export const Img = styled.img`
    width: 100%; 
    height: 50%;
`  
export const TopLine = styled.h5`
    color: var(--main-black); 
    font-size: 2rem; 
    line-height: 2rem; 
    padding: 1rem;
    text-align: center;

` 

export const Heading = styled.h6`
    color: var(--main-black);
    text-align: center;
    font-size: 1rem; 
    line-height: 2rem;
` 

export const Subtitle = styled.p`
    color: var(--main-black);
    text-align: center; 
`

export const Languages = styled.div` 
    display: ${({ languages }) => ( languages ? 'block' : 'none')}; 
    text-align: center;
    color: var(--main-black);
    font-size: 3rem; 
    padding-top: 3rem; 
    line-height: 2rem;
` 

export const ButtonElement = styled(Link)`
    display: ${({ button }) => (button ? 'block' : 'none')}; 
    background: var(--main-white); 
    border: none ;  
    margin: 0 auto; 
    padding-top: 1rem;
    text-decoration: none;
    transition: var(--main-transition);
`