// work on the waves and mobile style views of the nav

import React from 'react'; 
import image1 from '../Images/hi.png'; 
import image2 from '../Images/hey.png'; 
import { Slide } from 'react-awesome-reveal'; 

import { 
    HeroContainer, 
    HeroContent, 
    HeroHeader, 
    HeroParagraph, 
    Img1, 
    HeroImage,
    Img2,  
} from './HeroElements';

const Hero = ({ toggleNav }) => { 

  return (
    <HeroContainer id='home'> 
            
            <Slide cascade direction='down' triggerOnce>
              <HeroContent toggleNav={toggleNav}>
                    <HeroHeader>
                        Stephen Kimoi
                    </HeroHeader>

                    <HeroParagraph>
                        {/* <p>Creativity made simple</p>  */}
                        <p className="typing">Fullstack Blockchain Developer</p>
                    </HeroParagraph> 
              </HeroContent>
            </Slide>  


          <HeroImage>
               <Img1 toggleNav={toggleNav} src={image1} about='hero image'/> 
               <Img2 toggleNav={toggleNav} src={image2} about='hero image'/>
          </HeroImage>

    </HeroContainer>
  )
}

export default Hero