
import Footer from "../Footer";
import Hero from "../Hero";
import HireSection from "../Hire";
import InfoSection from "../InfoSection";
import { aboutMeSection, projectsSection } from "../InfoSection/Data";
import { experinceSection  } from '../Experience/Data'
import Navbar from "../Navbar"; 
import NavMobile from "../NavbarMobile";
import Preloader from '../Preloader'; 
import Experience from "../Experience";
import { DivOne } from "../Preloader/Preloader";
import { useState } from "react";

const Home = () => {
    const [ toggleNav, setToggleNav ] = useState(false) 

    const toggleNavBar = () => {
        setToggleNav(!toggleNav)
    } 
    
    const [hover, setHover] = useState(false) 
  
    const onHover = () => {
        setHover(!hover)
    } 

    const [done, setDone ] = useState(false) 
  
    const showPreloader = () => {
      setDone(true)
    }
    
    setTimeout( () => { 
        showPreloader()
    }, 3000) 
    
    return (
        <> 
            <Preloader done={done}/>

            <DivOne done={done}>
                <NavMobile toggleNav={toggleNav} toggleNavBar={toggleNavBar}/>
                <Navbar toggleNavBar={toggleNavBar} toggleNav={toggleNav}/> 
                <Hero toggleNav={toggleNav}/>   
                <InfoSection {...aboutMeSection} toggleNav={toggleNav}/> 
                <InfoSection {...projectsSection} onHover={onHover} hover={hover} toggleNav={toggleNav}/>
                <HireSection onHover={onHover} hover={hover} toggleNav={toggleNav}/>
                <Experience  {...experinceSection} onHover={onHover} hover={hover} toggleNav={toggleNav}/>
                <Footer toggleNav={toggleNav}/> 
            </DivOne>
        </>
    )
}

export default Home