import React from 'react'
import { ProjectsContainer, ProjectsWrapper, Projects, ProjectImage, ProjectsIntro, ProjectTitle, ProjectDescription} from '../ProjectsElements';
import { Fade } from "react-awesome-reveal"; 
import { data } from '../Data';



const DataStructuresAndAlgorithms = () => {

  const displayData = data.dsa.map( (article) => {
    return (
        <Projects href={article.url} target="_blank">
            <ProjectTitle>{article.title}</ProjectTitle> 
            <ProjectImage src={article.image} alt={article.alt}></ProjectImage>
            <ProjectDescription>{article.description}</ProjectDescription>
        </Projects>
    )
  })
  

  return (
    <div>
            <ProjectsContainer> 
               
                <ProjectsIntro>
                    <h3>DATA STRUCTURES AND ALGORITHMS CONCEPTS</h3>
                </ProjectsIntro>

                <Fade direction='left'>
                    <ProjectsWrapper>
                        {
                            displayData
                        }
                    </ProjectsWrapper>
                </Fade>


            </ProjectsContainer> 
      </div>
  )
}

export default DataStructuresAndAlgorithms