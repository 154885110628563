import React from 'react';  
import { AiFillHtml5, AiFillGithub } from 'react-icons/ai';  
import { DiCss3 } from 'react-icons/di'; 
import { SiJavascript, SiPython, SiPostgresql, SiFlask} from 'react-icons/si'; 
import { FaReact } from 'react-icons/fa'; 
import { Slide, Flip } from 'react-awesome-reveal';

import { 
    InfoContainer,  
    InfoWrapper, 
    InfoRow,
    Column1, 
    Column2, 
    ImgWrapper, 
    Img, 
    TopLine, 
    Heading, 
    Subtitle, 
    Languages, 
} from './InfoElements';

const Experience = ({ 
    id, 
    topline, 
    heading1,
    heading2, 
    heading3, 
    heading4, 
    heading5,  
    image, 
    imageAlt, 
    imageStart, 
    greyGround, 
    languages, 
    toggleNav
}) => { 

  return (
    
    <InfoContainer id={id} greyGround={greyGround}>
          
        <InfoWrapper>
            <InfoRow imageStart={imageStart} toggleNav={toggleNav}>

               <Slide direction='up' duration={2000} triggerOnce>
                    <Column1>
                    <TopLine>{topline}</TopLine>
                        <Heading>{heading1}</Heading> 
                        <Subtitle>
                        I lead and manage projects focused on driving positive change and innovation in the East African region, with a particular focus on hackathons and events
                        </Subtitle> 
                        <Heading>{heading2}</Heading> 
                        <Subtitle>
                        I played a pivotal role in establishing and growing the company. I actively engage in strategic decision-making, including product development, market expansion, and resource allocation
                        </Subtitle> 
                        <Heading>{heading3}</Heading> 
                        <Subtitle>
                        My role involves leading cross-functional teams, driving technical innovation, and ensuring data security and privacy to optimize operations and enhance efficiency.
                        </Subtitle> 
                        <Heading>{heading4}</Heading> 
                        <Subtitle>
                          Created, tested, and deployed smart contracts on the blockchain to automate various processes, including supply chain management and payment verification
                        </Subtitle> 
                        <Heading>{heading5}</Heading> 
                        <Subtitle>
                          Conducted extensive research & clear, concise, and engaging technical content on blockchain technology, cryptocurrencies and latest trends in the Web3 Kenya   
                        </Subtitle> 
                        
                        {/* <Languages languages={languages}>
                            <AiFillHtml5 /> 
                            <DiCss3 />
                            <SiJavascript />
                            <SiPython />
                            <AiFillGithub />
                            <SiPostgresql /> 
                            <FaReact /> 
                            <SiFlask />
                        </Languages> */}
                    </Column1> 
                </Slide>

                <Flip direction='left' fraction={1} triggerOnce>  
                    <Column2>
                        <ImgWrapper>
                            <Img src={image} alt={imageAlt}/>
                        </ImgWrapper>
                    </Column2>
                </Flip>

            </InfoRow>
        </InfoWrapper> 

    </InfoContainer>

  )
}

export default Experience