import React from 'react';  
import { AiFillHtml5, AiFillGithub, AiOutlineArrowRight } from 'react-icons/ai';  
import { DiCss3 } from 'react-icons/di'; 
import { SiJavascript, SiPython, SiPostgresql, SiFlask} from 'react-icons/si'; 
import { FaReact } from 'react-icons/fa'; 
import { Button } from '../ButtonElement';
import { Slide, Flip } from 'react-awesome-reveal';

import { 
    InfoContainer,  
    InfoWrapper, 
    InfoRow,
    Column1, 
    Column2, 
    ImgWrapper, 
    Img, 
    TopLine, 
    Heading, 
    Subtitle, 
    Languages, 
    ButtonElement
} from './InfoElements';

const InfoSection = ({ 
    id, 
    topline, 
    heading, 
    image, 
    subtitle, 
    imageAlt, 
    imageStart, 
    greyGround, 
    languages, 
    button, 

    hover, 
    onHover, 
    toggleNav
}) => { 

  return (
    
    <InfoContainer id={id} greyGround={greyGround}>
          
        <InfoWrapper>
            <InfoRow imageStart={imageStart} toggleNav={toggleNav}>

               <Slide direction='up' duration={2000} triggerOnce>
                    <Column1>
                        <TopLine>{topline}</TopLine>
                        <Heading>{heading}</Heading> 
                        <Subtitle>{subtitle}</Subtitle> 
                        <ButtonElement to="/Projects" button={button}> 
                            <Button onMouseEnter={onHover} onMouseLeave={onHover}>
                            Projects   {hover ?  <AiOutlineArrowRight /> : ''}
                            </Button>
                        </ButtonElement>  
                        
                        <Languages languages={languages}>
                            <AiFillHtml5 /> 
                            <DiCss3 />
                            <SiJavascript />
                            <SiPython />
                            <AiFillGithub />
                            <SiPostgresql /> 
                            <FaReact /> 
                            <SiFlask />
                        </Languages>
                    </Column1> 
                </Slide>

                <Flip direction='left' fraction={1} triggerOnce>  
                    <Column2>
                        <ImgWrapper>
                            <Img src={image} alt={imageAlt}/>
                        </ImgWrapper>
                    </Column2>
                </Flip>

            </InfoRow>
        </InfoWrapper> 

    </InfoContainer>

  )
}

export default InfoSection