import React from 'react'; 
import Navbar from "../Navbar"; 
import NavMobile from "../NavbarMobile";
import Footer from "../Footer";
import { useState } from 'react';
import { DivOne } from '../Preloader/Preloader';
import Preloader from '../Preloader';
import { ProjectsContainer, ProjectsWrapper, ProjectsIntro } from './ProjectsElements';
import { Fade } from 'react-awesome-reveal'; 
import ProjectLanding from './ProjectsLanding';

const ProjectsPage = ()=> {
  const [ toggleNav, setToggleNav ] = useState(false) 

  const toggleNavBar = () => {
      setToggleNav(!toggleNav)
  } 
  
  const [done, setDone ] = useState(false) 
  
    const showPreloader = () => {
        setDone(true)
    } 

   setTimeout( () => { 
    showPreloader()
   }, 3000) 
    

  return (
       <> 
       <Preloader done={done}/>

       <DivOne done={done}>
            <NavMobile toggleNav={toggleNav} toggleNavBar={toggleNavBar}/>
            <Navbar toggleNavBar={toggleNavBar} toggleNav={toggleNav}/> 

            <ProjectsContainer> 
               
                <ProjectsIntro>
                    <h3>MY PROJECTS</h3>
                </ProjectsIntro>
                
                <ProjectsWrapper toggleNav={toggleNav}>
                    <Fade direction='left'>
                        <ProjectLanding /> 
                    </Fade>
                </ProjectsWrapper>


            </ProjectsContainer> 

            <Footer /> 
      </DivOne>
      </> 
  )
}

export default ProjectsPage