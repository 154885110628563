import weather from '../Images/weather-app.png'; 
import kelvin from '../Images/kelvin-converter.png'; 
import tenzies from '../Images/tenziesUI.png'; 
import github from '../Images/github-finder.png'; 
import nameWebsite from '../Images/name-webiste.png'; 
import task from '../Images/task-list.png'; 
import investaFarm from '../Images/investafarm.png'; 
import inuaSauti from '../Images/inuasauti.png'; 
import dappStarter from '../Images/dappstarterkit.png'; 
import evmRustBridge from '../Images/evm-rust-bridge.png';
import ethTutorial from '../Images/eth-tutorial.png';
import moensToken from '../Images/moenstoken.png'; 
import wave from '../Images/waveUI.png'; 
import whitelist from '../Images/whitelist_dapp.png'; 
import cate_ui from '../Images/cate-ui.png'; 
import roy_ui from '../Images/roy-ui.png'; 
import nft_collection from '../Images/NFT-collection.png'; 
import cryptocurrency from '../Images/cryptocurrency.png'; 

export const data =  {
    "success": true, 
    "frontendProjects": [
        {
          projectTitle: "Developer Relations ICP Hub Kenya", 
          projectImage: "", 
          imageAlt: "Developer Relations", 
          projectURL: "https://icphubkenya.io/", 
          projectDescription: "Contributed to the growth of ICP Developer ecosystem and engaging with the community. During my tenure I've managed to: 1) Onboard over 500+ students and taught them how to build Decentralized Applications on the Internet Computer. 2) Oversaw the creation of 100+ MVPs on Internet Computer in the East African Region", 
        }, 
        {
          projectTitle: "Project Manager ICP Hub Kenya", 
          projectImage: "", 
          imageAlt: "Project Manager", 
          projectURL: "https://icphubkenya.io/", 
          projectDescription: "I led and managed impactful projects that drove innovation and positive change across the East African region. I successfully: 1) Organized, facilitated, and overseen 13+ hackathons, resulting in the creation of over 100 MVPs built on the Internet Computer Protocol (ICP). 2) Spearheaded events and hackathons in Kenya, Rwanda, and Uganda, fostering a vibrant ecosystem for tech innovation in East Africa.", 
        }, 
        , 
        {
          projectTitle: "CTO Investa Farm", 
          projectImage: "", 
          imageAlt: "CTO Investa Farm", 
          projectURL: "https://investafarm.com/", 
          projectDescription: "I've overseen the development, implementation, and maintenance of code", 
        }
    ], 
    "blockchainProjects": [

        {
          projectTitle: "Investa Farm", 
          projectImage: investaFarm,  
          imageAlt: "Investa Farm", 
          projectURL: "https://www.investafarm.com/", 
          githubLink: "https://github.com/Investa-Farm/InvestaFarm", 
          projectDescription: "Global two time award winning hackathon project. A platform that offers loans and investment opportunities to farmers in East Africa and Africa through Blockchain and AI" 
        },
        {
          projectTitle: "dApp Starter Kit", 
          projectImage: dappStarter, 
          imageAlt: "A dApp starter kit for fast building", 
          projectURL: "https://dapp-starterkit.vercel.app/", 
          githubLink: "https://github.com/KibokoDao-Africa/dApp-starter-kit", 
          projectDescription: "This is a starter kit for building decentralized applications (dApps). It contains a pre-written smart contract in solidity and frontend in react.js." 
        }, 
        {
          projectTitle: "ICP EVM Rust Bridge", 
          projectImage: evmRustBridge, 
          imageAlt: "A seamless Rust-based starter template for integrating ICP canisters with EVM-based smart contracts.", 
          projectURL: "https://inuxd-qiaaa-aaaal-qjigq-cai.icp0.io/", 
          githubLink: "https://github.com/Stephen-Kimoi/icp-evm-rust-bridge", 
          projectDescription: "This template provides a seamless integration between Internet Computer Protocol (ICP) canisters and Ethereum Virtual Machine (EVM) based smart contracts. It uses Rust for the backend canister and includes a simple Solidity smart contract for demonstration." 
        }, 
        {
          projectTitle: "ETH Payment Tutorial", 
          projectImage: ethTutorial, 
          imageAlt: "Step-by-step tutorial for building a decentralized ETH payment system on the Internet Computer.", 
          projectURL: "https://uu4vt-kqaaa-aaaap-abmia-cai.icp0.io/", 
          githubLink: "https://github.com/b3hr4d/eth_payment_tutorial", 
          projectDescription: "This comprehensive tutorial guides you through the process of building a decentralized e-commerce platform on the Internet Computer that can accept Ethereum (ETH) payments, handle withdrawals, and manage a digital storefront. Starting from a basic template, we'll incrementally add features to create a robust, cross-chain solution" 
        }, 
        // {
        //   projectTitle: "GET ETHER", 
        //   projectImage: wave, 
        //   imageAlt: "Get Ether dApp", 
        //   projectURL: "https://get-ether.netlify.app/", 
        //   projectDescription: "This is a Dapp that uses Ethereum blockchain to store messages that are sent via it. You also stand a chance to win some Ether once your message is sent.", 
        // },
        // {
        //   projectTitle: "WHITELIST DAPP", 
        //   projectImage: whitelist, 
        //   imageAlt: "Whitelist dApp", 
        //   projectURL: "https://steves-whitelist-dapp.vercel.app/", 
        //   projectDescription: "This is a Dapp that gives my supporters early access to a whitelist for my NFTs collection.", 
        // },
        // {
        //   projectTitle: "MY CRYPTOCURRENCY", 
        //   projectImage: cryptocurrency, 
        //   imageAlt: "My cryptocurrency", 
        //   projectURL: "https://moens-token.vercel.app/", 
        //   projectDescription: "A token linked to the MoensNFT collection", 
        // },
    ]
}