import styled from "styled-components"; 

export const HireContainer = styled.div`
   display: ${({ toggleNav}) => ( toggleNav ? 'none' : 'flex')}; 
   flex-direction: column;
   align-items: center; 
   justify-content: center; 
   width: 100%; 
   height: 100vh; 
   background: var(--main-grey);
` 

export const HireWrapper = styled.div`
   padding: 2rem; 
   align-items: center; 
   text-align: center; 
   color: var(--main-black); 
   font-size: 2rem;

   @media and screen (max-width: 50em){
       font-size: 0.5rem; 
   }
`

export const HireHeader = styled.h4`
   color: var(--main-black);
   text-align: center;
   font-size: 1rem; 
   line-height: 2rem;

   @media screen and (max-width: 50em){ 
      padding-top: 0rem;
      font-size: 3rem;
   }
` 

export const HireParagraph = styled.p`
   color: var(--main-black);
   text-align: center;
   font-size: 1rem; 
   line-height: 2rem;
`
