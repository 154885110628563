import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import { Link as LinkScroll } from "react-scroll"; 
import { Link as LinkRouter } from "react-router-dom";

export const NavMobileContainer = styled.aside`
    position: fixed;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 1rem; 
    top: ${({ toggleNav }) => (toggleNav ? '0' : '-100%')};
    transition: var(--main-transition);
    background-color: var(--main-white);

` 
export const CloseIcon = styled(FaTimes)`
    color: var(--main-black); 
` 
export const NavMobIcon = styled.div` 
   position: absolute;
   top: 1.2rem; 
   right: 1.5rem; 
   background: transparent; 
   font-size: 1.5rem; 
   cursor: pointer;
   outline: none;
` 

export const NavWrapper = styled.div`
   display: grid; 
   grid-template-columns: 1fr; 
   grid-template-rows: repeat(6,80px); 
   text-align: center; 
   background-color: var(--main-white); 

   @media screen and (max-width: 50em ){
       grid-template-rows: repeat(6, 60px); 
   }
`

export const NavMobLinkS = styled(LinkScroll)`
    text-decoration: none; 
    color: var(--main-black);
    cursor: pointer;

    &:hover {
        color: var(--main-red);
    }
`

export const NavMobLinkR = styled(LinkRouter)`
    text-decoration: none; 
    color: var(--main-black);
    cursor: pointer;

    &:hover {
        color: var(--main-red);
    }
`
