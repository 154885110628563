import img from '../Images/okay.png'

export const aboutMeSection = {
    id: "about", 
    topline: "About me", 
    heading: "I am a Blockchain Developer, Technical Web3 Writer, and Web3 Strategist with five years of software development experience, including three years in blockchain development on protocols like Ethereum, Polygon, Celo, and the Internet Computer. With over a year of leadership experience, I've successfully led teams and driven blockchain initiatives focused on societal well-being. My passion lies in using technology and leadership to make a positive impact on society.", 
    image: img, 
    imageAlt: "coding svg",
    imageStart: true, 
    greyGround: true, 
    languages: true, 
    button: false
} 

export const experinceSection = {
    id: "projects", 
    topline: "My Experience", 
    heading1: "Project Manager, ICP Hub Kenya. October 2023 - August 2024", 
    heading2: "Dev Rel, ICP Hub Kenya. January 2024 - August 2024", 
    heading3: "CTO, Investa Farm. July 2023 - current", 
    heading4: "Blockchain Developer, Investa Farm. May 2023 - July 2023", 
    heading5: "Lead Researcher & Technical writer, Blockwisely. June 2023 - October 2023", 
    image: img,  
    imageAlt: "projects svg", 
    imageStart: false, 
    greyGround: false, 
    languages: false, 
    button: true
}