import styled from "styled-components";  

export const ProjectsLanding = styled.a`
   width: 70%; 
   display: flex; 
   flex-direction: column;
   align-items: center; 
   justify-content: center;
   cursor: pointer;
   background-color: var(--mid-light-grey);
   margin: 2rem auto;
   box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
   padding: 1rem;

   &:hover {
      box-shadow: -5px 0px 48px 0px rgba(0,0,0,0.72);
   }
` 
export const LandingImage = styled.img`
   width: 70%;

   @media screen and (max-width: 50em) {
      width: 100%; 
      margin: 0.5rem auto 0 auto;
   }
`
export const LandingTitle = styled.h3`
   width: 100%; 
   color: var(--main-black);
   font-size: 1.8rem;
   padding: 1rem;
`

export const LandingDescription = styled.p`
   color: var(--main-blablack); 
   text-align: center; 
   font-size: 1.5rem; 
   padding: 1rem;
`