import React from 'react'
import { Button } from '../ButtonElement'
import { HiOutlineMail, HiOutlineMailOpen } from 'react-icons/hi'
import { HireContainer, HireWrapper, HireHeader, HireParagraph } from './HireElements'; 
import { Slide } from 'react-awesome-reveal';

const HireSection = ({ hover, onHover,toggleNav}) => {
  return ( 
        <HireContainer id="hire" toggleNav={toggleNav}>
            <Slide triggerOnce>

                <HireHeader>Contact me</HireHeader> 

                <HireWrapper>

                    <HireParagraph>For enquiries you can email: <b>kimoisteve.ke@gmail.com</b></HireParagraph>
                    <HireParagraph>Click on the link below to be re-directed to email</HireParagraph>

                    <Button href='mailto:kimoisteve.ke@gmail.com?subject=BUSINESS ENQUIRIES' onMouseEnter={onHover} onMouseLeave={onHover}> Email { hover ? <HiOutlineMailOpen /> : <HiOutlineMail /> }</Button>
                </HireWrapper>

            </Slide>
       </HireContainer>
  )
}

export default HireSection