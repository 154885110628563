import React, { useState, useEffect} from 'react'; 
import { animateScroll as scroll } from 'react-scroll/modules';
import { FaBars } from 'react-icons/fa'; 
import {
    NavContainer,  
    NavLogo, 
    NavList, 
    HamburgerBtn, 
    NavLinkR,
    NavLinkS, 
} from './NavbarElements'


const Navbar = ({ toggleNavBar, toggleNav }) => { 

  const [scrollNavbar, setScrollNav] = useState(false) 

  const onScroll = () => {
      if(window.scrollY >= 80){
          setScrollNav(true)
      } else {
          setScrollNav(false)
      }
  } 

  useEffect( () => {
      window.addEventListener('scroll', onScroll)
  })

  const toggleToHome = () => {
      scroll.scrollToTop(); 
  }

  return (
    <>
       <NavContainer scrollNavbar={scrollNavbar} toggleNav={toggleNav}> 
           <NavLogo onClick={toggleToHome} to="/">Steve Codes</NavLogo> 
           <HamburgerBtn onClick={toggleNavBar}>
               <FaBars />
           </HamburgerBtn>
           <NavList>
                <NavLinkR
                  to="/"
                  onClick={toggleToHome} 
                >
                   Home
                </NavLinkR> 

                <NavLinkS 
                   to="about"
                   spy={true}
                   smooth={true}
                   offset={-60}
                   duration={500} 
                   activeClass="active"
                >
                   About me
                </NavLinkS> 

                <NavLinkS 
                   to="projects"
                   spy={true}
                   smooth={true}
                   offset={-60}
                   duration={500} 
                   activeClass="active"
                >
                   Projects
                </NavLinkS> 

                <NavLinkS 
                  to="hire"
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={500} 
                  activeClass="active"
                >
                  Contact
                </NavLinkS> 

                <NavLinkR 
                  to="/Blog"
                >
                   Blog
                </NavLinkR> 
           </NavList>
       </NavContainer>
    </>
  )
}

export default Navbar; 