import styled from 'styled-components'; 
// import {Link as LinkRouter } from 'react-router-dom';

// Check out link router thats not functioning 

export const FooterContainer = styled.footer` 
    display:  ${({ toggleNav}) => ( !toggleNav ? 'flex' : 'none')};  
    align-items: center; 
    justify-content: center;
    width: 100%; 
    height: 30vh;
    background: var(--main-black); 
`

export const FooterWrapper = styled.div`
    display: grid; 
    align-items: center; 

`
export const FooterHeading = styled.h6`
    font-size: 1.5rem;
    color: var(--main-white);
    text-align: center;  
    margin-bottom: 0.5rem;
`

export const FooterIcon = styled.div`
    font-size: 2rem; 
    color: var(--main-white); 
    padding: 2rem;
`

export const IconsClass = styled.a` 
   cursor: pointer;
   padding: 1rem; 
   text-decoration: none;
   color: var(--main-white);

   &:hover {
       color: var(--main-red);
   }
`

export const FooterEnding = styled.p`
   color: var(--main-white); 
   text-align: center; 
   
`