import './App.css';
import { 
  BrowserRouter as Router,  
  Route,
  Routes,
  // Redirect
 } from 'react-router-dom';
 import { useState } from 'react';
import Home from './Components/pages';
import ProjectsPage from './Components/Projects'; 
import BlogsPage from './Components/Blog'; 
import FrontendProjects from './Components/Projects/FrontendProjects';
import BlockchainProjects from './Components/Projects/BlockchainProjects';
import BlockchainConcepts from './Components/Blog/BlockchainConcepts';
import DatastructuresAndAlgorithms from './Components/Blog/DataStructuresAndAlgorithms';

function App() {
  const [ toggleNav, setToggleNav ] = useState(false) 

  const toggleNavBar = () => {
      setToggleNav(!toggleNav)
  } 
  
  const [hover, setHover] = useState(false) 

  const onHover = () => {
      setHover(!hover)
  }

  return (
    <>

      <Router>
        <Routes>

          <Route path="/" element={<Home toggleNav={toggleNav} hover={hover} toggleNavbar={toggleNavBar} onHover={onHover}/>} />
          <Route path="/Projects" element={<ProjectsPage toggleNav={toggleNav} hover={hover} toggleNavbar={toggleNavBar} onHover={onHover}/>} /> 
          <Route path="/Blog" element={<BlogsPage toggleNav={toggleNav} hover={hover} toggleNavbar={toggleNavBar} onHover={onHover}/>} />
          <Route path="/achievements" element={<FrontendProjects />} /> 
          <Route path="/blockchain-projects" element={<BlockchainProjects />} />
          <Route path="/blockchain-concepts" element={<BlockchainConcepts />} />
          <Route path="/data-structures-and-algorithms" element={<DatastructuresAndAlgorithms />} />
  
          
        </Routes>
      </Router>
    </>
  );
}

export default App;
