import styled from "styled-components"; 

export const ProjectsContainer = styled.div`
   display: flex;
   width: 100%;
   flex-direction: column;
   justify-content: center;
   text-align: center;
   background-color: var(--light-grey);

   @media screen and (max-width: 50em){
      border: none;
   }
`

export const ProjectsIntro = styled.div`
   font-size: 1rem;
   max-width: 550px;
   margin: 1em auto 0em auto;
   color: var(--main-black);
   font-size: 1.5rem;

   @media screen and (max-width: 40em){
      max-width: 300px; 
      margin: 1.5em auto 0em auto;
   }
`

export const ProjectsWrapper = styled.div`
   display: ${({ toggleNav}) => ( toggleNav ? 'none' : 'grid')};
   grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); 
   grid-gap: 4rem; 
   width: 100%; 
   max-width: 1280px; 
   margin: 0 auto; 
   padding: 2rem; 
   margin-bottom: 6rem;

   @media screen and (max-width: 50em){
      width: calc(100% - 0.4rem);
   }
` 

export const Projects = styled.a`
   cursor: pointer;
   width: fit-content;
   margin: 1rem auto 0 auto; 
   box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
   text-align: center; 
   padding: 2rem; 
   background-color: var(--mid-light-grey);


   &:hover {
      box-shadow: -5px 0px 48px 0px rgba(0,0,0,0.72);
   }

   @media screen and (max-width: 50em){
      width: 100%;
   }
` 

export const ProjectImage = styled.img`
   width: 100%;

   @media screen and (max-width: 50em) {
      width: 100%; 
      margin: 0.5rem auto 0 auto;
   }
`
export const ProjectTitle = styled.h3`
   width: 100%; 
   color: var(--main-black);
   font-size: 1.5rem;
   margin-bottom: 2rem;
   padding-bottom: 1rem;
   border-bottom: solid 2px var(--main-black);
`

export const ProjectDescription = styled.p`
   color: var(--main-black); 
   text-align: center; 
   font-size: 1.5rem; 
   padding-top: 1rem;
`