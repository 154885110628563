import styled from 'styled-components'; 
import { Link as LinkRouter} from 'react-router-dom'; 
import { Link as LinkScroll } from 'react-scroll/modules';

export const NavContainer = styled.nav`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 10vh; 
    width: 100%;
    position: sticky;
    top: 0; 
    z-index: 10;  
    background: ${({ scrollNavbar }) => ( scrollNavbar ? 'var(--main-white)' : 'var(--main-white)')}; 

    @media screen and (max-width: 50em){
        display: ${({ toggleNav }) => (toggleNav ? 'none' : 'flex')};;
    }

` 

// Work on the react router for home logo 
export const NavLogo = styled(LinkRouter)`
    padding: 1rem;
    cursor: pointer;
    color: var(--main-black);
` 
export const HamburgerBtn = styled.div`
    cursor: pointer;
    display: none;

    @media screen and (max-width: 50em){
        display: block;
        transform: translate(-100%, 8%) 
    }
` 
export const NavList = styled.ul`
    display: flex;
    flex-direction: row;
    list-style: none;

    @media screen and (max-width: 50em){
        position: absolute;
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 90vh;
        top: -900%; 
        transition: var(--main-transition);
        background-color: var(--main-white);
    }
`
export const NavLinkS = styled(LinkScroll)`
    padding: 1.5rem; 
    color: var(--main-black);
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: var(--main-red);
    }

    &.active {
        border-bottom: 2px var(--main-black) solid;
    }
`

export const NavLinkR = styled(LinkRouter)`
    padding: 1.5rem; 
    color: var(--main-black);
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: var(--main-red);
    }
`