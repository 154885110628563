import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ProjectsLanding, LandingTitle, LandingDescription, LandingImage } from './ProjectsLandingElements'

const ProjectLanding = () => {
  const navigate = useNavigate(); 

  return (
    <div>
        <ProjectsLanding onClick={ () => { navigate('/achievements')} }>
          <LandingTitle>Achievements</LandingTitle>
          <LandingImage src='/frontend.jpg' alt='frontend'></LandingImage>
          <LandingDescription>Here are some of my achievements</LandingDescription>
        </ProjectsLanding>

        <ProjectsLanding onClick={ () => { navigate('/blockchain-projects')} }>
          <LandingTitle>Projects</LandingTitle>
          <LandingImage src='/blockchain.jpg' alt="blockchain"></LandingImage> 
          <LandingDescription>View some of the full-stack blockchain applications I've built</LandingDescription>
        </ProjectsLanding>
    </div>
  )
}

export default ProjectLanding