import React, {useState} from 'react'; 
import Navbar from "../Navbar"; 
import Footer from "../Footer";
import NavMobile from "../NavbarMobile";
import Preloader from '../Preloader'; 
import { DivOne } from '../Preloader/Preloader';
import { BlogsContainer, BlogsWrapper, BlogsIntro } from './BlogElements';
import { Fade } from 'react-awesome-reveal'; 
import BlogLanding from './BlogLanding';

const BlogsPage = () => {
    const [ toggleNav, setToggleNav ] = useState(false) 

    const toggleNavBar = () => {
        setToggleNav(!toggleNav)
    } 

    const [done, setDone ] = useState(false) 
  
    const showPreloader = () => {
      setDone(true)
    }

    setTimeout( () => { 
      showPreloader()
    }, 3000)

  return ( 
    <>
      <Preloader done={done}/>

       <DivOne done={done}>
            <NavMobile toggleNav={toggleNav} toggleNavBar={toggleNavBar}/>
            <Navbar toggleNavBar={toggleNavBar} toggleNav={toggleNav}/> 

            <BlogsContainer> 

               <BlogsIntro>
                    <h3>MY BLOGS</h3>
                </BlogsIntro>
                
                <Fade direction='right'>
                  <BlogsWrapper toggleNav={toggleNav}>
                     <BlogLanding />
                  </BlogsWrapper>
                </Fade>

            </BlogsContainer>

            <Footer />
        </DivOne>
    </>
  )
}

export default BlogsPage 