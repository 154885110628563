import React from 'react'
import ReactLoading from 'react-loading';
import { LoaderContainer } from './Preloader';


const Preloader = ({ done }) => {
  return (
    <>
      <LoaderContainer done={done}> 
        <ReactLoading type="spokes" color="black" height={200} width={100} className="loader"/>
      </LoaderContainer>
    </>
  )
}

export default Preloader 