import image1 from '../Images/laptop.png';
import image2 from '../Images/sticker.png'; 

export const aboutMeSection = {
    id: "about", 
    topline: "About me", 
    heading: "I am a Blockchain Developer, Technical Web3 Writer, and Web3 Strategist with five years of software development experience, including three years in blockchain development on protocols like Ethereum, Polygon, Celo, and the Internet Computer. With over a year of leadership experience, I've successfully led teams and driven blockchain initiatives focused on societal well-being. My passion lies in using technology and leadership to make a positive impact on society.", 
    subtitle: "", 
    image: image1, 
    imageAlt: "coding svg",
    imageStart: true, 
    greyGround: true, 
    languages: true, 
    button: false
} 

export const projectsSection = {
    id: "projects", 
    topline: "My projects and Achievements", 
    heading: "I have contributed to the growth of the ICP ecosystem by onboarding 500+ students and overseeing the creation of 100+ MVPs in East Africa. I organized 13+ hackathons across Kenya, Rwanda, and Uganda, fostering innovation in the region. As the lead developer at Investa Farm, I lead the development and execution of technology strategies, ensuring alignment with industry best practices.", 
    subtitle: "Click on the link below to view some of my personal projects and achievements:", 
    image: image2,  
    imageAlt: "projects svg", 
    imageStart: false, 
    greyGround: false, 
    languages: false, 
    button: true
}