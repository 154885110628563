import React from 'react'; 
import { animateScroll as scroll } from 'react-scroll/modules';
import { 
    NavMobIcon,  
    NavMobileContainer, 
    CloseIcon, 
    NavWrapper, 
    NavMobLinkS, 
    NavMobLinkR
} from './MobileElements'

const NavMobile = ({toggleNav, toggleNavBar}) => {  
  const toggleToHome = () => {
        scroll.scrollToTop(); 
  }

  return (
    <NavMobileContainer toggleNav={toggleNav} onClick={toggleNavBar}>
        <NavMobIcon onClick={toggleNavBar}>
            <CloseIcon /> 
        </NavMobIcon>

        <NavWrapper>

            <NavMobLinkR
               to="/" 
               onClick={toggleToHome}
            >
                Home
            </NavMobLinkR>

            <NavMobLinkS
                to="about"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500} 
                onClick={toggleNavBar}
            >
                About me
            </NavMobLinkS>

            <NavMobLinkS
                to="projects"
                spy={true}
                smooth={true}
                offset={-9}
                duration={500} 
                onClick={toggleNavBar}
            >
                Projects
            </NavMobLinkS>

            <NavMobLinkS 
               to="hire"
               spy={true}
               smooth={true}
               offset={-9}
               duration={500} 
               onClick={toggleNavBar} 
            >
                Contact
            </NavMobLinkS>

            <NavMobLinkR
               to="/Blog"
            >
                Blog
            </NavMobLinkR>
        </NavWrapper>
    </NavMobileContainer>
  )
}

export default NavMobile