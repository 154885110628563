// work on the waves and mobile style view of the hero 

import styled from "styled-components";

export const HeroContainer = styled.div`
    ${'' /* display: flex;
    flex-direction: row; 
    align-items: center; 
    jusit-content: space-between; 
    width: 100vw;   */}

   display: grid; 
   height: 100vh;
   width: 100%;
   grid-auto-columns: minmax(auto, 1fr); 
   align-items: center; 
   grid-template-areas: 'col2 col1'; 

   @media screen and (max-width: 33em){
       grid-template-areas: ' col1 '
                            ' col2 '; 
   }
` 
export const HeroContent = styled.div`
    display:  ${({ toggleNav}) => ( toggleNav ? 'none' : 'flex')}; 
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    padding-left: 0.5rem;
    margin-right: 8rem;
    font-size: 1.5rem;

    @media screen and (max-width: 50em){ 
        font-size: 1.5rem;        
        text-align: center; 
        margin: 0 auto;
    }
`

export const HeroHeader = styled.h3`
    text-align: center; 
    font-weight: bold;
    font-family: 'Quicksand', sans-serif;
    grid-area: 'col2';

` 

export const HeroImage = styled.div`
    max-width: 30rem; 
    height: 20rem;
    grid-area: 'col1';
`

export const Img1 = styled.img`
    width: 100%;
    margin: 0 0 10px 0; 
    padding-right: 0;
    display: ${({ toggleNav}) => ( toggleNav ? 'none' : 'block')};
    position:relative;
    animation-name: greet;
    animation-duration:2s;
    animation-timing-function:linear;

        @keyframes greet {
            from{
                bottom:-100%;
            }
            to {
                bottom:0;
            }
        }

    @media screen and (max-width: 33em){
        display: none;
    }
` 

export const HeroParagraph = styled.p`
   padding: 1rem; 
   line-height: 2rem;
   text-align: center;
`

export const Img2 = styled.img`
    width: 90%;
    margin: 0 0 10px 0; 
    padding-right: 0;
    display: none;
    
    @media screen and (max-width: 33em){
        display: ${({ toggleNav }) => ( toggleNav ? 'none' : 'block')};
        position:relative;
        animation-name: greet; 
        animation-duration:1s;
        animation-timing-function:linear;

        @keyframes greet {
            from{
                left:-100%;
            }
            to {
                left:0;
            }
        }
    }
   
`