import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ProjectsLanding, LandingTitle, LandingDescription, LandingImage } from './BlogLandingElements'

const BlogLanding = () => {
  const navigate = useNavigate(); 

  return (
    <div>

        <ProjectsLanding onClick={ () => { navigate('/blockchain-concepts')} }>
          <LandingTitle>Blockchain concepts</LandingTitle>
          <LandingImage src='/blockchain.jpeg' alt="blockchain"></LandingImage> 
          <LandingDescription>Articles on blockchain concepts</LandingDescription>
        </ProjectsLanding>
        
        <ProjectsLanding onClick={ () => { navigate('/data-structures-and-algorithms')} }>
          <LandingTitle>Data Structures and Algorithms</LandingTitle>
          <LandingImage src='/dsa.jpeg' alt='Data Structures and Algorithms'></LandingImage>
          <LandingDescription>Articles on Data Structures and Algorithms</LandingDescription>
        </ProjectsLanding>
        
    </div>
  )
}

export default BlogLanding