import styled from "styled-components";

export const LoaderContainer = styled.div`
   display: ${({ done }) => ( done ? 'none' : 'flex')};
   width: ${({ done }) => (done ? '0px' : '100vw')}; 
   height: ${({ done }) => (done ? '0px' : '90vh')}; 
   top: ${({ done }) => ( done ? '-400%' : '0')};
   justify-content: center; 
   align-items: center;
`

export const DivOne = styled.div`
   display: ${({ done }) => ( done ? 'block' : 'none')};
`