import blog1 from '../Images/blog1.png'; 
import blog2 from '../Images/blog2.png';
import blog3 from '../Images/blog3.png'; 
import blog4 from '../Images/blog4.png'; 
import gas from '../Images/gas.webp'; 
import mining from '../Images/mining.webp'; 
import nft from '../Images/nft.webp'; 

export const data = {
    "success": true, 
    "dsa": [
        {
          title: "101:Introduction to Data Structures and Algorithms", 
          image: blog1, 
          url: "https://dev.to/stephenkimoi/introduction-to-data-structures-and-algorithms-4jgd", 
          alt: "Introduction to Data Structures and Algorithms", 
          description: "In this blog, I have given a brief introduction and explanation on what data structures and algorithms are and how they are essential to every software developer", 
        },
        {
            title: "102(a):Deep dive into Data Structures and Algorithms", 
            image: blog2, 
            url: "https://dev.to/stephenkimoi/102a-deep-dive-into-data-structures-and-algorithms-1heb", 
            alt: "Deep dive into Data Structures and Algorithms", 
            description: "In this blog, I have done an in-depth explanation on the types of data structures that are commonly used and how to implement them in python", 
        },
        {
            title: "102(b):Deep dive into Data Structures and Algorithms", 
            image: blog3, 
            url: "https://dev.to/stephenkimoi/102bdeep-dive-into-data-structures-and-algorithms-hp8",
            alt: "Deep dive into Data Structures and Algorithms", 
            description: "In this blog, I have done an in-depth explanation of algorithms and further explained concepts such as space complexity, time complexity and asymptotic notations", 
        }, 
        {
            title: "103: Data structures implementation", 
            image: blog4, 
            alt: "Data structures implementation", 
            url: "https://dev.to/stephenkimoi/103-data-structures-implementation-1on6", 
            description: "In this blog, I have practically implemented some of the mostly used data structures in python. Enjoy!", 
        }, 
    ],
    "blockchainConcepts": [
        {
            title: "What is mining in blockchain?", 
            image: mining,  
            url: "https://dev.to/stephenkimoi/what-is-mining-in-blockchain-47mm",
            alt: "Mining in blockchain", 
            description: "Have you ever heard the term \"mining\" in the context of blockchain and thought it had something to do with extracting cryptocurrency from the earth? While that may sound funny, the concept of mining in blockchain is quite important and complex. Let's check it out."
        }, 
        {
            title: "What is gas in the Ethereum blockchain?", 
            image: gas,  
            url: "https://dev.to/stephenkimoi/what-is-gas-in-the-ethereum-blockchain-network-2o94",
            alt: "Gas in the Ethereum blockchain Network", 
            description: "Gas in the blockchain ecosystem is normally compared to the gasoline that is required by a car to move Why do we give such a comparison, and what is gas anyway? Let's explore"
        }, 
        {
            title: "Accessing NFT smart contract code", 
            image: nft,  
            url: "https://dev.to/stephenkimoi/accessing-nft-smart-contract-codes-56e6",
            alt: "NFT smart contract code", 
            description: "Web3 is fascinating! Did you know that you are able to access the source code of any NFT smart contract deployed in the Ethereum network? Let's dive deep in."
        },
    ]
}