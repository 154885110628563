import React from 'react'; 
import { AiOutlineLinkedin } from 'react-icons/ai'; 
import { FiTwitter, FiGithub } from 'react-icons/fi'; 
import { BsInstagram } from 'react-icons/bs';  
import { FooterContainer, FooterWrapper, FooterHeading, FooterIcon, IconsClass, FooterEnding } from './FooterElements';
import { JackInTheBox } from 'react-awesome-reveal';

const Footer = ( { toggleNav }) => {

  return (
        <FooterContainer toggleNav={toggleNav}>
            <JackInTheBox triggerOnce>
                <FooterWrapper>
                    <FooterHeading>Connect with me:</FooterHeading> 
                    <FooterIcon>

                        <IconsClass href='https://www.linkedin.com/in/stephen-kimoi' target="_blank">
                        <AiOutlineLinkedin /> 
                        </IconsClass>

                        <IconsClass href='https://twitter.com/stevekimoi' target="_blank">
                        <FiTwitter/> 
                        </IconsClass>

                        <IconsClass href='https://github.com/Stephen-Kimoi' target="_blank">
                            <FiGithub />
                        </IconsClass>

                        <IconsClass href='https://www.instagram.com/steve.kimoi/' target="_blank">
                        <BsInstagram /> 
                        </IconsClass>

                    </FooterIcon> 
                    <FooterEnding>&copy;Stephen Kimoi<br></br>Copyrights reserved</FooterEnding>
                </FooterWrapper>
            </JackInTheBox>
        </FooterContainer>
  )
}

export default Footer