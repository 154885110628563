import React from 'react'
import { ProjectsContainer, ProjectsWrapper, Projects, ProjectImage, ProjectsIntro, ProjectTitle, ProjectDescription, ProjectURL, ProjectGithub} from '../ProjectsElements';
import { Fade } from "react-awesome-reveal"; 
import { data } from '../Data';



const BlockchainProjects = () => {

  const displayData = data.blockchainProjects.map( (project) => {
    return (
        <Projects href={project.projectURL} target="_blank">
            <ProjectTitle>{project.projectTitle}</ProjectTitle> 
            <ProjectImage src={project.projectImage} alt={project.imageAlt}></ProjectImage>
            <ProjectDescription>{project.projectDescription}</ProjectDescription>
            <ProjectURL href={project.projectURL}>Live site</ProjectURL>
            <ProjectGithub href={project.githubLink}>Github Link</ProjectGithub>
        </Projects>
    )
  })
  

  return (
    <div>
            <ProjectsContainer> 
               
                <ProjectsIntro>
                    <h3>BLOCKCHAIN PROJECTS</h3>
                </ProjectsIntro>

                <Fade direction='left'>
                    <ProjectsWrapper>
                        {
                            displayData
                        }
                    </ProjectsWrapper>
                </Fade>


            </ProjectsContainer> 
      </div>
  )
}

export default BlockchainProjects