import React from 'react'
import { ProjectsContainer, ProjectsWrapper, Projects, ProjectImage, ProjectsIntro, ProjectTitle, ProjectDescription} from '../ProjectsElements';
import { Fade } from "react-awesome-reveal"; 
import { data } from '../Data';



const FrontendProjects = () => {

  const displayData = data.frontendProjects.map( (project) => {
    return (
        <Projects href={project.projectURL} target="_blank">
            <ProjectTitle>{project.projectTitle}</ProjectTitle> 
            <ProjectImage src={project.projectImage} alt={project.imageAlt}></ProjectImage>
            <ProjectDescription>{project.projectDescription}</ProjectDescription>
        </Projects>
    )
  })
  

  return (
    <div>
            <ProjectsContainer> 
               
                <ProjectsIntro>
                    <h3>MY ACHIEVEMENTS</h3>
                </ProjectsIntro>

                <Fade direction='left'>
                    <ProjectsWrapper>
                        {
                            displayData
                        }
                    </ProjectsWrapper>
                </Fade>


            </ProjectsContainer> 
      </div>
  )
}

export default FrontendProjects